@font-face {
    font-family: 'zona_problack';
    src: url('ZonaPro-Black.eot');
    src: url('ZonaPro-Black.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Black.woff2') format('woff2'),
    url('ZonaPro-Black.woff') format('woff'),
    url('ZonaPro-Black.ttf') format('truetype'),
    url('ZonaPro-Black.svg#zona_problack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro';
    src: url('ZonaPro-Regular.eot');
    src: url('ZonaPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Regular.woff2') format('woff2'),
    url('ZonaPro-Regular.woff') format('woff'),
    url('ZonaPro-Regular.ttf') format('truetype'),
    url('ZonaPro-Regular.svg#zona_pro') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_italic';
    src: url('ZonaPro-RegularItalic.eot');
    src: url('ZonaPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-RegularItalic.woff2') format('woff2'),
    url('ZonaPro-RegularItalic.woff') format('woff'),
    url('ZonaPro-RegularItalic.ttf') format('truetype'),
    url('ZonaPro-RegularItalic.svg#zona_pro_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'zona_pro_light';
    src: url('ZonaPro-Light.eot');
    src: url('ZonaPro-Light.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Light.woff2') format('woff2'),
    url('ZonaPro-Light.woff') format('woff'),
    url('ZonaPro-Light.ttf') format('truetype'),
    url('ZonaPro-Light.svg#zona_pro_light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_light_italic';
    src: url('ZonaPro-LightItalic.eot');
    src: url('ZonaPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-LightItalic.woff2') format('woff2'),
    url('ZonaPro-LightItalic.woff') format('woff'),
    url('ZonaPro-LightItalic.ttf') format('truetype'),
    url('ZonaPro-LightItalic.svg#zona_pro_light_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_hairline';
    src: url('ZonaPro-Hairline.eot');
    src: url('ZonaPro-Hairline.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Hairline.woff2') format('woff2'),
    url('ZonaPro-Hairline.woff') format('woff'),
    url('ZonaPro-Hairline.ttf') format('truetype'),
    url('ZonaPro-Hairline.svg#zona_pro_hairline') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_hairline_italic';
    src: url('ZonaPro-HairlineItalic.eot');
    src: url('ZonaPro-HairlineItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-HairlineItalic.woff2') format('woff2'),
    url('ZonaPro-HairlineItalic.woff') format('woff'),
    url('ZonaPro-HairlineItalic.ttf') format('truetype'),
    url('ZonaPro-HairlineItalic.svg#zona_pro_hairline_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'zona_pro_bold';
    src: url('ZonaPro-Bold.eot');
    src: url('ZonaPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Bold.woff2') format('woff2'),
    url('ZonaPro-Bold.woff') format('woff'),
    url('ZonaPro-Bold.ttf') format('truetype'),
    url('ZonaPro-Bold.svg#zona_pro_bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_extrabold';
    src: url('ZonaPro-ExtraBold.eot');
    src: url('ZonaPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-ExtraBold.woff2') format('woff2'),
    url('ZonaPro-ExtraBold.woff') format('woff'),
    url('ZonaPro-ExtraBold.ttf') format('truetype'),
    url('ZonaPro-ExtraBold.svg#zona_pro_extrabold_italic') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_extrabold_italic';
    src: url('ZonaPro-ExtraBoldItalic.eot');
    src: url('ZonaPro-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-ExtraBoldItalic.woff2') format('woff2'),
    url('ZonaPro-ExtraBoldItalic.woff') format('woff'),
    url('ZonaPro-ExtraBoldItalic.ttf') format('truetype'),
    url('ZonaPro-ExtraBoldItalic.svg#zona_pro_extrabold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'zona_pro_bold_italic';
    src: url('ZonaPro-BoldItalic.eot');
    src: url('ZonaPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-BoldItalic.woff2') format('woff2'),
    url('ZonaPro-BoldItalic.woff') format('woff'),
    url('ZonaPro-BoldItalic.ttf') format('truetype'),
    url('ZonaPro-BoldItalic.svg#zona_pro_bold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'zona_prosemibold';
    src: url('ZonaPro-SemiBold.eot');
    src: url('ZonaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-SemiBold.woff2') format('woff2'),
    url('ZonaPro-SemiBold.woff') format('woff'),
    url('ZonaPro-SemiBold.ttf') format('truetype'),
    url('ZonaPro-SemiBold.svg#zona_prosemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_prosemibold_italic';
    src: url('ZonaPro-SemiBoldItalic.eot');
    src: url('ZonaPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-SemiBoldItalic.woff2') format('woff2'),
    url('ZonaPro-SemiBoldItalic.woff') format('woff'),
    url('ZonaPro-SemiBoldItalic.ttf') format('truetype'),
    url('ZonaPro-SemiBoldItalic.svg#zona_prosemibold_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zona_problack_italic';
    src: url('ZonaPro-BlackItalic.eot');
    src: url('ZonaPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-BlackItalic.woff2') format('woff2'),
    url('ZonaPro-BlackItalic.woff') format('woff'),
    url('ZonaPro-BlackItalic.ttf') format('truetype'),
    url('ZonaPro-BlackItalic.svg#zona_problack_italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'zona_pro_thin';
    src: url('ZonaPro-Thin.eot');
    src: url('ZonaPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-Thin.woff2') format('woff2'),
    url('ZonaPro-Thin.woff') format('woff'),
    url('ZonaPro-Thin.ttf') format('truetype'),
    url('ZonaPro-Thin.svg#zona_pro_thin') format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'zona_pro_thin_italic';
    src: url('ZonaPro-ThinItalic.eot');
    src: url('ZonaPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('ZonaPro-ThinItalic.woff2') format('woff2'),
    url('ZonaPro-ThinItalic.woff') format('woff'),
    url('ZonaPro-ThinItalic.ttf') format('truetype'),
    url('ZonaPro-ThinItalic.svg#zona_pro_thin_italic') format('svg');
    font-weight: 100;
    font-style: normal;
}

.zona-pro-black {
    font-family: 'zona_problack';
}

.zona-pro {
    font-family: 'zona_pro';
}

.zona-pro-italic {
    font-family: 'zona_pro_italic';
}

.zona-pro-light {
    font-family: 'zona_pro_light';
}

.zona-pro-light-italic {
    font-family: 'zona_pro_light_italic';
}

.zona-pro-hairline {
    font-family: 'zona_pro_hairline';
}

.zona-pro-hairline-italic {
    font-family: 'zona_pro_hairline_italic';
}

.zona-pro-bold {
    font-family: 'zona_pro_bold';
}

.zona-pro-bold-italic {
    font-family: 'zona_pro_bold_italic';
}

.zona-pro-semibold {
    font-family: 'zona_prosemibold';
}

.zona-pro-semibold-italic {
    font-family: 'zona_prosemibold_italic';
}

.zona-problack-italic {
    font-family: 'zona_problack_italic';
}

.zona-pro-thin {
    font-family: 'zona_pro_thin';
}

.zona-pro-thin-italic {
    font-family: 'zona_pro_thin_italic';
}

.zona-pro-extrabold {
    font-family: 'zona_pro_extrabold';
}

.zona-pro-extrabold-italic {
    font-family: 'zona_pro_extrabold_italic';
}
