div#root div.app {
    height: 100%;
    width: 100%;
}

input::-webkit-input-placeholder { /* WebKit browsers */
    color:    lightgrey;
}
input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    lightgrey;
}
input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    lightgrey;
}
input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color:    lightgrey;
}
