:global html,
:global body {
    height: 100%;
}

:global h1 {
    font-size: 2em;
    font-family: zona_pro_bold;
    font-weight: bold;
}

:global h2 {
    font-size: 2em;
}

:global h3 {
    font-size: 1.25em;
}

:global p {
    font-size: 1em;
}

:global .uppercase {
    text-transform: uppercase;
}

/*Input start*/
/* The container */
:global .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 11px;
    font-family: "zona_pro_light";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
:global .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
:global .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
/*:global .container:hover input ~ .checkmark {*/
/*    */
/*}*/

/* When the checkbox is checked, add a blue background */
:global .container input:checked ~ .checkmark {
    background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
:global .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
:global .container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
:global .container .checkmark:after {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 6px;
    height: 13px;
    border: solid #2fa85a;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
}

/*Input stop*/

.main {
    font-family: zona_pro;
    display: flex;
    flex-direction: row;
    color: white;
    text-align: center;
    font-size: 20px;
    min-height: 100vh;
}

/* Form styles */
:global .form-container {
    width: 50%;
    background-color: #ed278a;
}

:global .form-container input {
    font-size: 0.8em;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    height: 3.6vh;
    padding-left: 5px;
    border: none;
    font-family: zona_pro;
}

:global .form-container :focus {
    outline: none;
}

:global .form-container form div input {
    width: 100%;
}

:global .form-container .width-100 {
    width: 100%;
}

:global ::placeholder {
    color: black;
}

:global .form-container form .street,
:global .form-container form .birth-date,
:global .form-container form .phone {
    width: 45%;
}

:global .form-container form .birth-date [class*="Input_correct"] {
    position: absolute;
    top: 10px;
    right: 10px;
}

:global #BirthDate {
    display: none;
}

:global .react-datepicker-wrapper {
    float: left;
    width: 100%;
}

:global .form-container form .street-no,
:global .form-container form .apartment-no {
    width: 24%;
}

:global .form-container form .post-code {
    width: 36%;
}

:global .form-container form .city {
    width: 60%;
}

:global .error {
    font-family: zona_prosemibold;
    color: #000000;
    font-size: 11px;
    width: 100%;
    text-align: left;
    padding-top: 5px;
}

:global .errorBg {
    background-color: #ffd0d6;
    border: 2px solid #000000 !important;
}

:global a {
    /*text-decoration: none;*/
    color: white;
}

:global .text-input p {
    text-align: left;
    font-size: 0.7em;
    margin: 10px 0 5px 0;
}

:global label.container {
    margin-top: 12px;
    margin-bottom: 0;
    text-align: left;
}

:global .form-container button[type="submit"] {
    font-family: zona_pro;
    letter-spacing: 3px;
    margin: 20px 0;
    padding: 15px 40px;
    font-size: 1.2em;
    text-align: center;
    color: white;
    background-color: black;
    border: none;
}

:global .form-container .agreement {
    margin-bottom: 15px;
}

:global .flex {
    display: flex;
    margin-bottom: 10px;
}

:global #consultantForm {
    padding: 0 20%;
}

@media screen and (max-width: 1536px) {
    .main {
        font-size: 18px;
    }
}

@media screen and (max-width: 1440px) {
    .main {
        font-size: 17px;
    }

    :global #consultantForm {
        padding: 0 19%;
    }

    :global .form-container button[type="submit"] {
        font-size: 0.7em;
    }
}

@media screen and (max-width: 1280px) {
    .main {
        font-size: 16px;
    }
}

@media screen and (max-width: 1024px) {
    .main {
        flex-direction: column;
        padding: 5% auto;
        font-size: 20px;
        background: #3da7a2;
    }

    :global .form-container {
        width: 100%;
    }
}

@media screen and (max-width: 834px) {
    .main {
        font-size: 18px;
    }
}

@media screen and (max-width: 768px) {
    .main {
        font-size: 18px;
    }
}

@media screen and (max-width: 680px) {
    .main {
        font-size: 16px;
    }

    :global h1 {
        font-size: 2.3em;
    }

    :global h3 {
        font-size: 1.1em;
    }

    :global p {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 480px) {
    .main {
        font-size: 14px;
    }

    :global h1 {
        font-size: 2em;
    }

    :global h3 {
        font-size: 1em;
    }

    :global .form-container button[type="submit"] {
        font-size: 0.7em;
    }

    :global .form-container form .street-no,
    :global .form-container form .apartment-no {
        width: 46%;
    }

    :global #consultantForm {
        padding: 0 15%;
    }
}

@media screen and (max-width: 375px) {
    .main {
        font-size: 16px;
    }

    :global .form-container form .street,
    :global .form-container form .city,
    :global .form-container form .post-code,
    :global .form-container form .birth-date,
    :global .form-container form .phone {
        width: 100%;
    }

    :global .form-container input {
        font-size: 1.1em;
        height: 30px;
    }
}
