:global .form-container {
    color: #181818;
    background-color: #ffffff;
}
:global .form-container input {
    font-family: zona_pro;
    height: 26px;
    outline: 0;
    padding: 4px 0px 4px 8px;
    border: none;
    border-bottom: 1px solid #a2acae;
    font-size: 16px;
    color: #546264;
    background-color: #fff;
}
:global .form-container .agreement {
    margin-bottom: 8px;
}
:global .errorBg {
    border-bottom: 1px solid #851168!important;
    background-color: rgba(133,17,104,.05) !important;
}
:global .error {
    font-family: zona_prosemibold;
    color: #851168;
    font-size: 10px;
    width: 100%;
    text-align: left;
    padding-top: 4px;
    margin-bottom: -15px;
}
:global .checkmark, :global  .container input:checked~.checkmark {
    background-color: #fff;
    border: 1px solid #a2acae;
}
:global a {
    color: #181818;
    font-weight: 600;
}
:global .text-input p {
    text-align: left;
    font-size: .8em;
    margin: 12px 0 0px;
}
:global .picker_picker__2hL_b {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    align-items: center;
    text-align: left;
    background-color: #fff;
    width: 103%;
    font-size: .8em;
    height: 6.9vh;
    border-bottom: 1px solid #a2acae;
    padding: 10px 0px;
}
:global .picker_menus__23qvW {
    font-family: zona_pro;
    font-size: 12px;
}
:global .picker_spTheme__3wTLf {
    --styled-select-option__padding: 8px 4px;
}
@media screen and (max-width: 680px) {
    :global .text-input p {
    font-size: .8em;
}
:global .img-box img {
    max-width: 40%;
}
}
@media screen and (max-width: 480px) {
    :global #consultantForm {
    padding: 0 10%;
}
}

/*thankYou.css*/
:global .main {
    color: #181818;
    background-color: #ffffff;
}