.box {
    width: 20%;
}

.box img {
    height: 4em;
}

.text {
    margin-top: 15%;
    font-size: 0.9em;
}

@media screen and (max-width: 768px) {
    .box {
        width: 26%;
    }
    .text {
        margin-top: 0;
    }
}

@media screen and (max-width: 375px) {
    .box {
        width: 35%;
    }
}
