.correct {
    position: absolute;
    top: 35px;
    right: 25px;
}

.correct:after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 13px;
    border: solid #2fa85a;
    border-width: 0px 2px 2px 0;
    transform: rotate(40deg);
}

.textInput {
    position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.zipBox {
    position: absolute;
    z-index: 100;
    width: 100%;
    text-align: left;
    padding: 5px 5px 10px 5px;
    background-color: #fff;
    max-height: 200px;
    overflow: auto;
    border: 1px solid black;
    border-top: transparent;
}

@media screen and (max-width: 1370px) {
    .correct:after {
        top: -5px;
    }
}

@media screen and (max-width: 1110px) {
    .correct:after {
        top: 10px;
    }
}

@media screen and (max-width: 800px) {
    .correct:after {
        top: 0;
    }
}
