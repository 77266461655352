.container {
    width: 50%;
    display: flex;
    flex-direction: column;
    background: url("../../../assets/images/dolacz-do-klubu/artboard1.jpg") 50%
        100% no-repeat;
    background-size: cover;
}

.text {
    margin-bottom: 5%;
}

.m-b {
    margin-bottom: 5%;
}

.icons_row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 90%;
    margin: 1% auto;
}

.img {
    width: 100%;
}

.img img {
    max-width: 60%;
}

.desktop {
    display: block;
}

.icons_box h3 {
    margin-bottom: 2em;
}

@media screen and (max-width: 1280px) {
    .icons_row {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .desktop {
        display: none;
    }

    .container {
        order: 2;
        width: 100%;
    }

    .text {
        order: 1;
    }

    .icons_box {
        order: 2;
    }

    .icons_row {
        width: 90%;
    }

    .bottom {
        order: 3;
        margin-top: 5%;
    }

    .img {
        order: 4;
    }
    .container {
        background: #3da7a2;
    }
}

@media screen and (max-width: 834px) {
    .icons_row {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .icons_row {
        width: 100%;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 480px) {
    .container {
        min-height: 700px;
        background: url("../../../assets/images/dolacz-do-klubu/artboard2.jpg")
            50% 100% no-repeat;
        background-size: cover;
    }
}
